import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout/Layout"
import { Helmet } from "react-helmet"
import { renderTags } from "../../../utilities/renderTags"
import Template from "../../../components/Template/Template"
import PerimeterForm from "../../../components/PerimeterForm/PerimeterForm"
import { LOCALES } from "../../../utilities/utilities"
import { useScrollTracking } from "../../../analytics/useScrollTracking"
import { usePageTracking } from "../../../analytics/usePageTracking"
import getPageData from "../../../analytics/getPageData"
export const query = graphql`
  query {
    template: contentfulTemplate(
      id: { eq: "f39e6348-c21b-506f-ae5d-b1022a97a964" }
    ) {
      ...templateFields
    }
    site {
      siteMetadata {
        defaultLocale
      }
    }
  }
`
const PerimetreAgicap = props => {
  useScrollTracking()
  usePageTracking()
  const locale = LOCALES.ES
  const trackingData = getPageData(
    locale,
    {
      pagePerimeter: "landing_page",
      pagePublication: "",
      pageFeature: "account_payable"
    },
    "lp-payer-vos-factures",
    props.path
  )
  const {
    template,
    site: {
      siteMetadata: { defaultLocale }
    }
  } = props.data

  const pageData = {
    hubspotForm: {
      name: "",
      formId: "dbf1bb69-661e-471d-bf9c-8a12d3f75a17",
      portalId: "2856475",
      eventAction: "",
      eventLabel: ""
    },
    formTitle: "Perimetro Agicap"
  }
  return (
    <Layout>
      <Helmet>
        <title>Perimetro Agicap</title>
        <script type="text/javascript">{`${trackingData}`}</script>
        {template &&
          template.headTags &&
          renderTags(locale, defaultLocale, template.headTags)}
      </Helmet>
      <Template {...template} defaultLocale={defaultLocale} locale={locale}>
        <PerimeterForm
          {...pageData}
          locale={locale}
          defaultLocale={defaultLocale}
        />
      </Template>
    </Layout>
  )
}

PerimetreAgicap.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
}

export default PerimetreAgicap
